
import { defineComponent, ref } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import store from "@/store";
import "vue-router/dist/vue-router";
import { useRouter } from 'vue-router';

interface LoginDTO {
  email: string;
  password: string;
}

export default defineComponent({
  name: "login",
  components: {},
  setup() {
    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const formData = ref<LoginDTO>({
      email: "",
      password: "",
    });

    const router = useRouter();

    function navigate() {
      router.push({ name: "admin-dashboard" });
    }

    const rules = ref({
      email: [
        {
          required: true,
          message: "Email is required",
          trigger: "change",
        },
      ],
      password: [
        {
          required: true,
          message: "Password is required",
          trigger: "change",
        },
      ],
    });

    const submit = () => {
      if (!formRef.value || loading.value) {
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          loading.value = true;

          let payload = {
            email: formData.value.email,
            password: formData.value.password,
          };

          store
            .dispatch(Actions.LOGIN, payload)
            .then(() => {
              navigate();
            })
            .catch(() => {
              loading.value = false;
            });
        } else {

          return false;
        }
      });
    };

    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
    };
  },
});
